import React from 'react';
import { PageWrapper } from '../components/base';
import UserLogin from '../components/profile/UserLogin';

const LoginPage = () => (
  <PageWrapper title="Login">
    <section className="section-first">
      <article className="inner-width inner-width-padding">
        <UserLogin />
      </article>
    </section>
  </PageWrapper>
);

export default LoginPage;
